import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`A select is usually used in forms. It's best utilized in a case when a user has
more than four options to choose from.`}</p>
    <ul>
      <li parentName="ul">{`Always order the list of options in a logical order (eg. alphabetically,
numerically)`}</li>
      <li parentName="ul">{`Try to select a default value`}</li>
      <li parentName="ul">{`If there's no logical default, try to use a clear placeholder text.`}</li>
    </ul>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`The selection menu, with a list of possible options, can be triggered by
clicking into the text input field. A user can then select a single option after
which the text input field updates to show the selected option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Select wrap=function",
        "componentName": "Select",
        "wrap": "function"
      }}>{`() => {
  const options = ['Option1', 'Option2', 'Option3', 'Option4'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="mySelect"
      value={value}
      placeholder="Make a selection..."
      onChange={e => setValue(e.target.value)}
      label="Which option do you want?"
      message="I always have at least 4 available options"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`A select should be used in cases where there are more than 4 options`}</li>
      <li parentName="ul">{`Always try to select a default option when possible`}</li>
    </ul>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`When a user has to make a single selection from less than 4 options, use the
`}<a parentName="li" {...{
          "href": "/components/radio/code"
        }}>{`radio`}</a>{` component or
`}<a parentName="li" {...{
          "href": "/components/choice/code"
        }}>{`choice radio`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      